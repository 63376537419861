@import url(//db.onlinewebfonts.com/c/345c037c81b29b91048e08ac4f5f3364?family=Gazpacho+Black);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Poppins', sans-serif;
}

@font-face {font-family: "Gazpacho Black"; src: url("//db.onlinewebfonts.com/t/345c037c81b29b91048e08ac4f5f3364.eot"); src: url("//db.onlinewebfonts.com/t/345c037c81b29b91048e08ac4f5f3364.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/345c037c81b29b91048e08ac4f5f3364.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/345c037c81b29b91048e08ac4f5f3364.woff") format("woff"), url("//db.onlinewebfonts.com/t/345c037c81b29b91048e08ac4f5f3364.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/345c037c81b29b91048e08ac4f5f3364.svg#Gazpacho Black") format("svg"); }



@media screen and (max-width: 499px) {

  .title{
    width: 300px;
    margin-bottom: 5%;
  }

  .imgMain2 {
    display: none;
  }

  .imges2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

  }

  .imges2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imgCon {
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;

  }

  .btn3-2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
  }

  .btn3-2 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .imgSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .UtilDiv1 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 20%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 12%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .imgMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .UtilDivM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .UtilDiv {
    display: none;
  }

  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyHM {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-top: 7%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH {
    display: none;
  }

  .storyH1M {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-top: 3%;
    color: #bfbdb8;
    line-height: 1;
  }

  .storyH1 {
    display: none;
  }

  .cT {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 5%;
    font-size: 18px;
  }

  .storyCon2 {
    color: white;
    margin-top: 5%;
    font-size: 18px;

  }

  .storyPic1 {
    width: 300px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .storyPic2M {
    width: 350px;
    justify-content: flex-start;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 7%;
    padding-left: 7%;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }







  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }



  .nftamount {

    color: #027381;
    font-size: 60px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    cursor: pointer;
  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 25px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 200px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #02a2b8;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 1;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 85%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }

  .hoboDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 8%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon {
    margin-top: 5%;
    width: 45%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 85px;
  }


  .imgAndCon2M {
    margin-top: 5%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .imgAndCon2M div {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
  }

  .imgAndCon2M img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 85px;
  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 18px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 45px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }

  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  
  .title{
    width: 300px;
    margin-bottom: 5%;
  }
  .imgMain2 {
    display: none;
  }


  .imges2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

  }

  .imges2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imgCon {
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;

  }

  .imgSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .UtilDiv1 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center; 
    padding-top: 20%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 12%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .imgMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .UtilDivM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .UtilDiv {
    display: none;
  }

  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyHM {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-top: 7%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH {
    display: none;
  }

  .storyH1M {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-top: 3%;
    color: #bfbdb8;
    line-height: 1;
  }

  .storyH1 {
    display: none;
  }

  .cT {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 5%;
  }

  .storyCon2 {
    color: white;
    margin-top: 5%;
  }

  .storyPic1 {
    width: 400px;
    justify-content: flex-start;
    display: flex;
  }

  .storyPic2M {
    width: 450px;
    justify-content: flex-start;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 7%;
    padding-left: 7%;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }







  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }



  .nftamount {

    color: #027381;
    font-size: 60px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    cursor: pointer;

  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 30px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 250px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #0193a7;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 1;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 70%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }

  .hoboDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 3%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon {
    margin-top: 5%;
    width: 45%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .imgAndCon2M {
    margin-top: 5%;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .imgAndCon2M div {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
  }

  .imgAndCon2M img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;

  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 45px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }

  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
 
  .title{
    width: 300px;
    margin-bottom: 5%;
  }
 
  .imgMain2 {
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;

  }

  .btn3-2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
  }

  .btn3-2 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .imgSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .UtilDiv1 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 20%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 12%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .imgMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .UtilDivM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .UtilDiv {
    display: none;
  }

  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyHM {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-top: 7%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH {
    display: none;
  }

  .storyH1M {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-top: 3%;
    color: #bfbdb8;
    line-height: 1;
  }

  .storyH1 {
    display: none;
  }

  .cT {
    font-size: 45px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 5%;
  }

  .storyCon2 {
    color: white;
    margin-top: 5%;
  }

  .storyPic1 {
    width: 400px;
    justify-content: flex-start;
    display: flex;
  }

  .storyPic2M {
    width: 450px;
    justify-content: flex-start;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 7%;
    padding-left: 7%;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }



  .nftamount {

    color: #027381;
    font-size: 60px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    cursor: pointer;

  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 30px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 250px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #0299ad;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 1;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 70%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }

  .hoboDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 3%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon {
    margin-top: 5%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
    ;
  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 45px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }

  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .title{
    width: 360px;
    margin-bottom: 5%;

  }

.imgAndCon2M{
display: none;
}

  .imges2 {
    display: flex;
    flex-flow: row nowrap;
    display: none;
  }

  .imges2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;

  }

  .btn3-2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32%;
  }

  .btn3-2 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .UtilDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 12%;
    padding-right: 5%;
    padding-top: 20%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 12%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 12%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);


  }

  .imgMain2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .UtilDivM {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    display: none;
  }

  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyHM {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-top: 3%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH {
    display: none;
  }

  .storyH1M {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-top: 3%;
    color: #bfbdb8;
    line-height: 1;
  }

  .storyH1 {
    display: none;
  }

  .cT {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 2.5%;
  }

  .storyCon2 {
    color: white;
    margin-top: 2.5%;
  }

  .storyPic1 {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .storyPic2M {
    width: 620px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }








  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }



  .nftamount {

    color: #027381;
    font-size: 60px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    cursor: pointer;

  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 38px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 300px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #02a2b8;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 25%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .hoboDiv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;

  }

  .imgAndCon2 div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon2 img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
    ;
  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 55px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }

  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .title{
    width: 360px;
    margin-bottom: 5%;

  }
  
  .imgAndCon2M{
    display: none;
    }
  .imges2 {
    display: flex;
    flex-flow: row nowrap;
    display: none;
  }

  .imges2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;

  }

  .btn3-2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
  }

  .btn3-2 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .UtilDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .imgMain2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .UtilDivM {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    display: none;
  }

  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyHM {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-top: 3%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH {
    display: none;
  }

  .storyH1M {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-top: 3%;
    color: #bfbdb8;
    line-height: 1;
  }

  .storyH1 {
    display: none;
  }

  .cT {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 2.5%;
  }

  .storyCon2 {
    color: white;
    margin-top: 2.5%;
  }

  .storyPic1 {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .storyPic2M {
    width: 620px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }







  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }



  .nftamount {

    color: #027381;
    font-size: 60px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    cursor: pointer;

  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 40px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 300px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #02a2b8;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 22%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .hoboDiv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;

  }

  .imgAndCon2 div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon2 img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
    ;
  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 55px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }

  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  
  .title{
    width: 360px;
    margin-bottom: 5%;
  }
  
  .imgAndCon2M{
    display: none;
    }
  .imgAndCon2M {
    display: none;
  }

  .imges2 {
    display: flex;
    flex-flow: row nowrap;
    display: none;
  }

  .imges2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;
  }

  .UtilDivM {
    display: none;
  }

  .storyH1M {
    display: none;
  }

  .storyHM {
    display: none;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .imgMain2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 18%;
    padding-right: 18%;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH1 {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
  }

  .cT {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 5%;
    width: 470px;
  }

  .storyCon2 {
    color: white;
    margin-top: 5%;
    width: 600px;
  }

  .storyPic {
    width: 500px;
  }

  .storyPic1 {
    width: 500px;
  }

  .storyPic2 {
    width: 480px;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;
  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }





  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {

    color: #027381;
    font-size: 60px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 20%;
    padding-right: 20%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    cursor: pointer;

  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 40px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .btn3-2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3-2 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 300px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #027381;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 15%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .hoboDiv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .imgAndCon2 div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon2 img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 5%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 55px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }



  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  
  .title{
    width: 360px;
    margin-bottom: 5%;

  }
  
  .imgAndCon2M{
    display: none;
    }
  .imges2 {
    display: flex;
    flex-flow: row nowrap;
    display: none;
  }

  .imges2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;

  }

  .btn3-2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .btn3-2 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .UtilDivM {
    display: none;
  }

  .storyH1M {
    display: none;
  }

  .storyHM {
    display: none;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .imgMain2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 25%;
    padding-right: 25%;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH1 {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
  }

  .cT {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 5%;
    width: 470px;
  }

  .storyCon2 {
    color: white;
    margin-top: 5%;
    width: 600px;
  }

  .storyPic {
    width: 500px;
  }

  .storyPic1 {
    width: 500px;
  }

  .storyPic2 {
    width: 480px;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }







  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }



  .nftamount {

    color: #027381;
    font-size: 60px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 20%;
    padding-right: 20%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    width: 45px;
    cursor: pointer;

  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 40px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 300px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #02a2b8;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 15%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .hoboDiv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }


  .imgAndCon2 div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon2 img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 55px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }

  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  
  .title{
    width: 360px;
    margin-bottom: 5%;

  }
  
  .imgAndCon2M{
    display: none;
    }

  .imges2 {
    display: flex;
    flex-flow: row nowrap;
    display: none;
  }

  .imges2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;

  }

  .btn3-2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .btn3-2 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .UtilDivM {
    display: none;
  }

  .storyH1M {
    display: none;
  }

  .storyHM {
    display: none;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding-left: 28%;
    padding-right: 28%;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding-left: 28%;
    padding-right: 28%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 28%;
    padding-right: 28%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .imgMain2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 25%;
    padding-right: 25%;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH1 {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
  }

  .cT {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 5%;
    width: 470px;
  }

  .storyCon2 {
    color: white;
    margin-top: 5%;
    width: 600px;
  }

  .storyPic {
    width: 500px;
  }

  .storyPic1 {
    width: 500px;
  }

  .storyPic2 {
    width: 480px;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }








  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }



  .nftamount {
    color: #027381;
    font-size: 60px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 20%;
    padding-right: 20%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    width: 45px;
    cursor: pointer;

  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 40px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 300px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #02a2b8;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 15%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .hoboDiv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .imgAndCon2 div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon2 img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 55px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }

  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 3840px) {
  .title{
    width: 360px;
    margin-bottom: 5%;

  }
  .imgAndCon2M{
    display: none;
    }
  
  .imges2 {
    display: flex;
    flex-flow: row nowrap;
    display: none;
  }

  .imges2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: none;
  }

  .disconnect {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #004943;
    padding: 10px;
    font-weight: 500;

  }

  .btn3-2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .btn3-2 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .UtilDivM {
    display: none;
  }

  .storyH1M {
    display: none;
  }

  .storyHM {
    display: none;
  }

  a {
    color: rgb(193, 208, 248);
    font-size: large;
    cursor: pointer;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 200vh;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;

  }

  .boxWrap2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .bgColorSpecial {
    background: rgb(2, 115, 129);
    background: linear-gradient(180deg, rgba(2, 115, 129, 1) 0%, rgba(13, 68, 61, 1) 63%);
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding-left: 35%;
    padding-right: 35%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding-left: 35%;
    padding-right: 35%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 35%;
    padding-right: 35%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .boxWrap3-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);
  }

  .boxWrap3-2-2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(2, 114, 129, 0);

  }

  .imgMain2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 35%;
    padding-right: 35%;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .UtilDiv2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #d7f0d3;
    line-height: 1;
  }

  .storyH1 {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
  }

  .cT {
    font-size: 56px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    margin-top: 10%;
    color: #bfbdb8;
    line-height: 1;
    text-align: left;
  }

  .cT2 {
    color: white;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
  }

  .cTMain {
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-size: 60px;
    font-family: "Gazpacho Black";
    margin-bottom: 10px;
    color: #d7f0d3;
    line-height: .5;
  }

  .storyH2-1 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10%;
    color: #5e845f;
    font-weight: 700;
  }

  .storyCon {
    color: white;
    margin-top: 5%;
    width: 470px;
  }

  .storyCon2 {
    color: white;
    margin-top: 5%;
    width: 600px;
  }

  .storyPic {
    width: 500px;
  }

  .storyPic1 {
    width: 500px;
  }

  .storyPic2 {
    width: 480px;
  }


  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .uR2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: #d7f0d3;
    font-family: "Gazpacho Black";
    text-align: center;
    margin-bottom: 1.7%;
  }

  .intro2 {
    color: #d7f0d3;
    font-size: 17px;
    text-shadow: 4px 3px 0 #383d6e18;
    text-align: right;
    margin-top: -5%;
    font-family: "Gazpacho Black";

  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: 30%;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: -10%;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

  }







  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }



  .nftamount {

    color: #027381;
    font-size: 60px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    padding-top: 5%;
    background-image: url(/static/media/starry-bg.2289ee55.png);
    background-size: cover;
  }

  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: none;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 20%;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: #040e0d;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2%;
    padding-left: 20%;
    padding-right: 20%;
  }

  .footer2 {

    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .f2 {
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .twitterF {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    width: 45px;
    cursor: pointer;

  }

  .copyright {
    color: #0b4e3a;
    text-align: left;
    font-size: 40px;
    padding-bottom: 5px;
    font-family: "Gazpacho Black";
    line-height: 1;
  }

  .copyright2 {
    color: #0b4e3a;
    text-align: left;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 5%;
  }

  .f1 {
    display: flex;
    flex-flow: column nowrap;
  }


  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25%;
  }

  .btn3 img {
    width: 25px;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: #000000;

  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .hobo {
    width: 500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 50px;
    border: white 2px solid;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 15px;
    padding: 50px 50px 45px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    letter-spacing: 3px;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .owl {
    width: 35%;
  }

  .owl2 {
    width: 35%;
    margin-top: 12%;
  }

  .owl2-2 {
    width: 150px;
    margin-top: 8%;
    border-radius: 100%;
    border: 4px solid #314d57;
  }

  .owlT {
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
    font-weight: bold;
  }

  .owlT2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: -15%;
  }

  .osAndlr img {
    width: 25px;
  }

  .osAndlr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
  }

  .osAndlrCW {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #027381;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12%;
    width: 300px;
    color: white;
    font-weight: bold;
    border: none;
    transition: ease-in .2s;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlrCW:hover {
    background-color: #02a2b8;
  }

  .osAndlr div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .btn2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .btn2-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27%;
  }

  .box1 {
    background-color: #191433;
    padding: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box1-2 {
    background-color: #06383d;
    padding: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .box2 {
    background-color: #120e25;
    padding: -60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .box2-2 {
    background-color: #052926;
    padding: 20px;
    width: 15%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  .hoboDiv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }


  .osAndlr2-3 img {
    width: 25px;
  }

  .osAndlr2-3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #1c1948;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .osAndlr2-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #5252ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2%;
    width: 400px;
    color: #8c83b9;
    border: none;
    font-size: 15px;
  }

  .osAndlr2-3 div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
    font-weight: 600;
    font-size: 15px;
    color: #5252ff;
  }

  .cloud {
    margin-top: -15%;
    background-size: contain;
    width: 100%;
  }

  .imgAndCon div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .imgAndCon2 div {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
  }

  .imgAndCon2 img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .imgT {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0b4e3a;
    color: #5c4808;
  }

  summary:hover {
    color: #775e0c;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    color: white;
  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 55px;
    color: #257e73;
    font-family: "Gazpacho Black";
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background-color: #040e0d;
  }

  .prices {
    margin-top: 5%;
    margin-bottom: 5%;

  }

  .pricesN {
    margin-top: 15%;
    margin-bottom: 15%;

  }

  .prices1 {
    color: rgb(179, 179, 179);
  }

  .prices2 {
    color: white;
    font-weight: 500;
  }

  .prices3 {
    color: #0168d2;
    font-weight: 500;
    font-size: 14px;
  }


  .bold {
    font-weight: bold;
  }

  .minT {
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10%;
  }

  .section3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .mint2Con {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
  }

  .mint2Con1 {
    color: white;
    font-weight: bold;
    font-size: 17px;
    margin-top: 10%;
  }

  .mint2Con1-1 {
    color: #009e49;
    font-weight: bold;
    font-size: 17px;

  }

  .mint2Con2 {
    color: rgb(179, 179, 179);
    font-size: 15px;
  }

  .btns1 {
    color: #abb298;
    background-color: #251e4a;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;
  }

  .btns2 {
    color: white;
    background-color: #009f50;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    width: 60px;

  }

  .cT {}

  .btns1Main {
    margin-right: 10%;

  }

  .fLine {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .globe {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .line1 {
    display: flex;
    flex-flow: column nowrap;
    width: 2px;
    height: 150%;
    background-color: #064906;
    margin-left: auto;
    margin-right: auto;
  }
}
